import React, { Component } from "react";
import { Input, Form } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import _trim from "lodash/trim";
import { quickSearch } from "../../redux/actions";

class QuickSearch extends Component {
  state = {
    searchTerm: "",
  };

  componentDidMount() {
    this.setState({ searchTerm: this.props.searchTerm });
  }

  handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.quickSearch(_trim(this.state.searchTerm));
    this.props.history.push("/quick-search");
  }

  render() {
    return (
      <Form className="navbar-form float-left" role="search" style={{ width: "35%", marginRight: 180 }} onSubmit={(e) => this.handleSubmit(e)}>
        <div className="position-relative has-icon-right">
          <Input
            style={{ maxHeight: "24px" }}
            id="search-term"
            type="text"
            className="form-control round"
            placeholder="Quick Search"
            onChange={(e) => this.setState({ searchTerm: e.target.value })}
            value={this.state.searchTerm}
            autoComplete={"off"}
          />
          <div className="form-control-position">
            <FaSearch className="mb-1 cursor-pointer" onClick={() => this.handleSubmit()} />
          </div>
        </div>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  let menu = get(state, "user.menu.default", []);
  return {
    searchTerm: state.quickSearch.searchTerm,
    roles: state.user.roles || [],
    menu,
  };
}

export default connect(mapStateToProps, { quickSearch })(withRouter(QuickSearch));
