import { postDataAPI } from "../../api";

export const quickSearch = (searchTerm) => async (dispatch) => {
  const dispatchObj = {
    type: "QUICK_SEARCH",
    searchTerm,
    resources: {
      patient: {
        filter: { "resource.name::text ILIKE": `%${searchTerm}%` },
        count: 0,
      },
      practitioner: {
        filter: { "resource.name::text ILIKE": `%${searchTerm}%` },
        count: 0,
      },
      encounter: {
        filter: { or: [{ "resource.reasonCode::text ILIKE": `%${searchTerm}%` }, { "resource.type::text ILIKE": `%${searchTerm}%` }] },
        count: 0,
      },
      medicationrequest: {
        filter: { "resource.medicationCodeableConcept.text ILIKE": `%${searchTerm}%` },
        count: 0,
      },
    },
  };
  dispatch({ ...dispatchObj, loading: true });
  // Patient
  const patientCount = await postDataAPI("/patient/get-count", { filter: dispatchObj.resources.patient.filter });
  dispatchObj.resources.patient.count = patientCount.total_count;
  // Practitioner
  const practitionerCount = await postDataAPI("/practitioner/get-count", { filter: dispatchObj.resources.practitioner.filter });
  dispatchObj.resources.practitioner.count = practitionerCount.total_count;
  // Encounter
  const encounterCount = await postDataAPI("/encounter/get-count", { filter: dispatchObj.resources.encounter.filter });
  dispatchObj.resources.encounter.count = encounterCount.total_count;
  // Medication Request
  const medicationrequestCount = await postDataAPI("/medicationrequest/get-count", { filter: dispatchObj.resources.medicationrequest.filter });
  dispatchObj.resources.medicationrequest.count = medicationrequestCount.total_count;

  let hasResults = false;
  for (const c in dispatchObj.resources) {
    if (dispatchObj.resources[c].count > 0) {
      hasResults = true;
      break;
    }
  }
  dispatch({ ...dispatchObj, loading: false, hasResults });
};
