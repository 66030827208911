import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";
import { FaBars, FaEllipsisV, FaUser, FaSignOutAlt, FaChevronDown } from "react-icons/fa";
import { signout } from "../../../redux/actions";
import QuickSearch from "../../../components/quickSearch/index";
import DatasourceSelectorModal from "../../../views/datasources/datasourceSelectorModal";
class ThemeNavbar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      showDatasourceSelector: false,
    };
  }

  handleClick = () => {
    this.props.toggleSidebarMenu("open");
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { first_name, last_name, image_url, preferences } = this.props.user;
    const { datasource } = preferences || {};
    return (
      <>
        {this.state.showDatasourceSelector && (
          <DatasourceSelectorModal
            isOpen={this.state.showDatasourceSelector}
            close={() => this.setState({ showDatasourceSelector: false })}
            defaultSelected={datasource}
          />
        )}
        <Navbar className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid px-0">
            <div className="navbar-header" style={{ width: "80%" }}>
              <FaBars className="navbar-toggle float-left d-lg-none" onClick={this.handleClick.bind(this)} data-toggle="collapse" />
              <QuickSearch />
              <Button color="link" size="lg" className="m-0 p-0" onClick={() => this.setState({ showDatasourceSelector: true })}>
                <span>Current Data Asset: </span>
                <span style={{ fontWeight: 500 }}>{(datasource || {}).label || "FHIR-1000"}</span>
              </Button>
              <FaEllipsisV style={{ marginRight: "-66px" }} className="navbar-toggle float-right d-lg-none" onClick={this.toggle} />
            </div>
            <div className="navbar-container">
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto float-right" navbar>
                  <UncontrolledDropdown nav inNavbar className="pr-1">
                    <DropdownToggle nav>
                      {image_url ? (
                        <img alt="profile" src={image_url} width={20} className="mr-1" style={{ borderRadius: "50%" }} />
                      ) : (
                        <FaUser size={20} className="mr-1" />
                      )}
                      {`${first_name || ""} ${last_name || ""}`} <FaChevronDown size={15} />
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link to="/user-profile" className="p-0">
                        <DropdownItem>
                          <FaUser size={16} className="mr-1" /> My Profile
                        </DropdownItem>
                      </Link>
                      <DropdownItem divider />
                      <Link to="/login" className="p-0" onClick={() => this.props.signout()}>
                        <DropdownItem>
                          <FaSignOutAlt size={16} className="mr-1" /> Logout
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </div>
          </div>
        </Navbar>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  signout,
})(withRouter(ThemeNavbar));
