// import external modules
import React from "react";

// import internal(own) modules
import { Row, Col } from "reactstrap";

const Error = () => {
  return (
    <div className="container-fluid body-bg bg-lighten-3 text-muted">
      <div className="container">
        <Row className="full-height-vh">
          <Col md="12" lg="12" className="d-flex align-items-center justify-content-center">
            <div className="error-container">
              <div className="no-border">
                <div className="text-center text-bold-600 grey darken-1 mt-5" style={{ fontSize: "10rem", marginBottom: "4rem" }}>
                  Oops...
                </div>
              </div>
              <div className="error-body">
                <Row className="pt-4 pb-2">
                  <Col xs="12" className="text-center" style={{ fontSize: "2em" }}>
                    Something went wrong, a log as been received by the developer.
                    <br />
                    <br />
                    Please reload the page (Ctrl + F5) or click the button below.
                  </Col>
                </Row>
                <Row className="py-2 justify-content-center">
                  <Col>
                    <a href="/" className="btn btn-primary btn-raised btn-block font-medium-2">
                      <i className="ft-home" /> Back to Home
                    </a>
                  </Col>
                </Row>
              </div>
              <div className="error-footer bg-transparent">
                <Row>
                  <p className="text-muted text-center col-12 py-1">© {new Date().getFullYear()}</p>
                  {/* <Col xs="12" className="text-center">
                    <Button className="btn-social-icon mr-1 mb-1 btn-facebook">
                      <span className="fa fa-facebook" />
                    </Button>
                    <Button className="btn-social-icon mr-1 mb-1 btn-twitter">
                      <span className="fa fa-twitter" />
                    </Button>
                    <Button className="btn-social-icon mr-1 mb-1 btn-linkedin">
                      <span className="fa fa-linkedin" />
                    </Button>
                    <Button className="btn-social-icon mr-1 mb-1 btn-github">
                      <span className="fa fa-github" />
                    </Button>
                  </Col> */}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Error;
