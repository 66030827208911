import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import { postDataAPI } from "../../api";
import { ClipLoader } from "react-spinners";

export default function DatasourceSelectorModal({ isOpen, close, defaultSelected }) {
  const selectedId = (defaultSelected || {}).value || "484fa957-b166-4afc-a0ba-46542506e880";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getData() {
    const data = await postDataAPI("/fhir-datasource/get", {
      filter: {
        "dal IS NOT": null,
      },
      order: {
        field: "title",
        direction: "asc",
      },
      limit: 50,
    });
    setData(data);
  }

  async function selectDatasource(fhir_datasource_id) {
    setLoading(true);
    await postDataAPI("/fhir-datasource/user-set", { fhir_datasource_id });
    window.location.reload();
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={() => close()} size="md" contentClassName="modal-content-bg-grey">
      <ModalHeader close={<FaTimes onClick={close} className="cursor-pointer" size={20} />}>Select Data Asset</ModalHeader>
      <ModalBody>
        {loading ? (
          <div className="text-center mt-4 mb-4">
            <ClipLoader size={80} />
          </div>
        ) : (
          <Row>
            {data.map((m, i) => {
              return (
                <Col key={i} sm="12" md="4" lg="4">
                  <Card className="text-center">
                    <CardBody>
                      <div>{m.title}</div>
                      <div className="mt-2">{m.size}</div>
                      <div>
                        {m.fhir_datasource_id === selectedId ? (
                          <Button color="primary" className="m-0 mt-2" disabled>
                            Current
                          </Button>
                        ) : (
                          <Button color="primary" className="m-0 mt-2" onClick={() => selectDatasource(m.fhir_datasource_id)} disabled={loading}>
                            Select
                          </Button>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
}
