// import external modules
import React, { PureComponent } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
// import internal(own) modules
import { FoldedContentConsumer, FoldedContentProvider } from "../utility/context/toggleContentContext";
import Sidebar from "./components/sidebar/sidebar";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";

class MainLayout extends PureComponent {
  state = {
    width: window.innerWidth,
    sidebarState: "close",
  };

  updateWidth = () => {
    this.setState(() => ({
      width: window.innerWidth,
    }));
  };

  componentDidMount() {
    if (window !== "undefined") {
      window.addEventListener("resize", this.updateWidth, false);
    }
  }

  componentWillUnmount() {
    if (window !== "undefined") {
      window.removeEventListener("resize", this.updateWidth, false);
    }
  }

  toggleSidebarMenu = (sidebarState) => {
    this.setState({ sidebarState });
  };

  render() {
    return (
      <FoldedContentProvider>
        <FoldedContentConsumer>
          {(context) => (
            <div
              style={{ paddingTop: 50 }}
              className={classnames("wrapper ", {
                "menu-collapsed": context.foldedContent || this.state.width < 991,
                "main-layout": !context.foldedContent,
                "sidebar-sm": true,
              })}
            >
              <Sidebar toggleSidebarMenu={this.toggleSidebarMenu} sidebarState={this.state.sidebarState} />
              <Navbar toggleSidebarMenu={this.toggleSidebarMenu} sidebarState={this.state.sidebarState} />
              <main>{this.props.children}</main>
              <Footer user={this.props.user} />
            </div>
          )}
        </FoldedContentConsumer>
      </FoldedContentProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user || {},
});

export default connect(mapStateToProps, {})(MainLayout);
