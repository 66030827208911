const defaultState = {
  collapsed: localStorage.getItem("SIDEBAR_COLLAPSED") === "true" ? true : false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "SET_MENU":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
