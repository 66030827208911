import axios from "axios";
import apiUrls from "../../config";

import { postDataAPI } from "../../api";

function setLocalStorage(token, user_id, roles, preferences) {
  if (token) localStorage.setItem("token", token);
  localStorage.setItem("user_id", user_id);
  localStorage.setItem("roles", JSON.stringify(roles));
  localStorage.setItem("preferences", JSON.stringify(preferences));
}

export const setUserState = (state) => {
  return {
    type: "SET_USER",
    payload: state,
  };
};

export const providerAuth = (payload, callback) => {
  return async (dispatch) => {
    try {
      setLocalStorage(payload.token, payload.user_id, payload.roles, payload.preferences);
      dispatch({ type: "SET_USER", payload });
      callback();
    } catch (e) {
      console.log(e);
    }
  };
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const response = await postDataAPI("/users/current");
    dispatch({ type: "SET_USER", payload: response });
    return response;
  } catch (e) {
    if (e.response) {
      return e.response.data;
    } else {
      console.log(e);
    }
  }
};

export const signin = ({ email, password }) => {
  return async (dispatch) => {
    try {
      const formattedEmail = email.toLowerCase();
      const response = await postDataAPI("/users/signin", {
        email: formattedEmail,
        password,
      });
      setLocalStorage(response.token, response.user_id, response.roles, response.preferences);
      await dispatch({ type: "SET_USER", payload: response });
      return response;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "CLEAR_USER" });
      localStorage.clear();
    } catch (e) {
      console.log(e);
    }
  };
};

export const userPublicApiRequest = async (uri, payload, callback) => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    const response = await axios.post(`${apiUrls.USER_PUBLIC_URL}${uri}`, payload, config);
    if (callback) {
      callback(response.data);
    }
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateCurrentUser = (payload, callback) => {
  return async (dispatch) => {
    try {
      const response = await postDataAPI("/users/update-current", payload);
      setLocalStorage(null, response.user_id, response.roles, response.preferences);
      dispatch({ type: "SET_USER", payload: response });
      if (callback) {
        callback();
      }
      return response;
    } catch (e) {
      console.log(e);
    }
  };
};

export const switchUser = (payload, callback, callbackRes) => {
  return async (dispatch) => {
    try {
      const response = await postDataAPI("/users/switch-user", payload);
      const pref = response.preferences || {};
      if (!payload.switchBack) {
        pref.login_as = localStorage.getItem("userId");
      } else {
        delete pref.login_as;
      }
      setLocalStorage(response.token, response.user_id, response.roles, pref);
      dispatch({ type: "SET_USER", payload: response });
      if (callback) {
        callback(); // callback for redirecting user
      }
      if (callbackRes) {
        callbackRes(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
};
