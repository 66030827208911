import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import { withErrorBoundary } from "react-error-boundary";
import ErrorPage from "../views/error";
import { logClientError, getCurrentUser } from "../redux/actions";

export default (ChildComponent) => {
  class ComposedComponent extends Component {
    // Our component just got rendered
    componentDidMount() {
      if (!this.props.user.has_current_user_data) {
        this.props.getCurrentUser();
      }
      this.shouldNavigateAway();
    }
    // Our component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    checkRoles(urlList, roles) {
      const userRoles = !this.props.user.roles ? [] : this.props.user.roles;
      // eslint-disable-next-line
      for (const u in urlList) {
        if (
          roles.indexOf("*") === -1 &&
          this.props.location.pathname.indexOf(urlList[u]) > -1 &&
          userRoles.filter((value) => roles.includes(value)).length === 0
        ) {
          this.props.history.push("/");
        }
      }
    }

    shouldNavigateAway() {
      if (!this.props.user.token) {
        this.props.history.push("/login");
      }
      if (this.props.menu.length > 0) {
        let defaultPath;
        // eslint-disable-next-line
        for (const m in this.props.menu) {
          if (this.props.menu[m].isDefault) {
            defaultPath = this.props.menu[m].link;
          }
          if (!this.props.menu[m].subMenuLinks) {
            const { link, relatedLinks, roles } = this.props.menu[m];
            this.checkRoles([link, ...relatedLinks], roles);
          } else {
            // eslint-disable-next-line
            for (const s in this.props.menu[m].subMenuLinks) {
              const { link, relatedLinks, roles } = this.props.menu[m].subMenuLinks[s];
              this.checkRoles([link, ...relatedLinks], roles);
            }
          }
        }
        if (this.props.location.pathname === "/" && defaultPath) {
          this.props.history.push(defaultPath);
        }
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  const onErrorHandler = (error, componentStack) => {
    if (error.name === "ChunkLoadError") {
      // New version available prompt refresh
      const event = new Event("ChunkLoadError");
      window.dispatchEvent(event);
      // eslint-disable-next-line no-undef
    } else if (process.env.REACT_APP_UI_ENV === "cloud") {
      logClientError({ errorStack: error.stack, componentStack });
    }
  };

  function mapStateToProps(state) {
    let menu = get(state, "user.menu.default", []);
    let isDefaultMenu = true;
    return {
      user: state.user,
      menu,
      isDefaultMenu,
    };
  }

  return connect(mapStateToProps, { getCurrentUser })(withRouter(withErrorBoundary(ComposedComponent, ErrorPage, onErrorHandler)));
};
