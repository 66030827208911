// import external modules
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { FaToggleOff, FaToggleOn, FaTimes } from "react-icons/fa";
import classnames from "classnames";
import { FoldedContentConsumer } from "../../../../utility/context/toggleContentContext";

import { FaFireAlt } from "react-icons/fa";
class SidebarHeader extends Component {
  handleClick = () => {
    this.props.toggleSidebarMenu("close");
  };

  render() {
    return (
      <FoldedContentConsumer>
        {(context) => (
          <div
            style={{ overflow: "hidden" }}
            className={classnames("sidebar-header", {
              collapsed: this.props.collapsed,
            })}
          >
            <div className="logo clearfix">
              <NavLink to="/" className={`logo-text`}>
                <div className="logo-img">
                  <FaFireAlt
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: 10,
                      marginBottom: 8,
                      color: "white",
                    }}
                  />
                  <span className="sidebar-header-text text-white" style={{ fontSize: 20 }}>
                    FhirFish
                  </span>
                </div>
              </NavLink>
              <span className="nav-toggle d-none d-sm-none d-md-none d-lg-block">
                {context.foldedContent ? (
                  <FaToggleOff onClick={context.makeNormalContent} className="toggle-icon" size={16} />
                ) : (
                  <FaToggleOn onClick={context.makeFullContent} className="toggle-icon" size={16} />
                )}
              </span>
              <span href="" className="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose">
                <FaTimes onClick={this.handleClick} size={20} />
              </span>
            </div>
          </div>
        )}
      </FoldedContentConsumer>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user || {},
    collapsed: state.menu.collapsed,
  };
}

export default connect(mapStateToProps)(SidebarHeader);
