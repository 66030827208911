import axios from "axios";
import config from "./config";
import { store } from "./redux/store";

const axiosInstance = axios.create({
  baseURL: config.API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    if (error.response.status === 401) {
      localStorage.clear();
      store.dispatch({ type: "CLEAR_USER" });
    }
    return Promise.reject(error);
  }
);

export const postDataAPI = async (uri, payload, config) => {
  const defaultConfig = {
    headers: { Authorization: localStorage.getItem("token") },
    ...config,
  };
  const response = await axiosInstance.post(uri, payload, defaultConfig);
  return response.data;
};

export const downloadFile = async (bucket, fileName) => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") },
    };
    const bucketAccessResponse = await axiosInstance.post(
      `/system/bucket-access`,
      {
        fileName,
        bucket,
        operation: "getObject",
        signatureVersion: "v4",
      },
      config
    );
    return bucketAccessResponse.data.signedRequest;
  } catch (e) {
    console.log("Error:", e);
  }
};
