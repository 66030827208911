import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

function isJsonStr(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const middlewares = [thunk, createDebounce()];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  {
    user: {
      token: localStorage.getItem("token"),
      user_id: localStorage.getItem("user_id"),
      roles: JSON.parse(isJsonStr(localStorage.getItem("roles")) ? localStorage.getItem("roles") : null),
      preferences: JSON.parse(isJsonStr(localStorage.getItem("preferences")) ? localStorage.getItem("preferences") : null),
    },
  },
  composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
