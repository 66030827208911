// import external modules
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
// Styling
import "../../../assets/scss/components/sidebar/sidebar.scss";
// import internal(own) modules
import SideMenuContent from "./sidemenu/sidemenu";
import SidebarHeader from "./sidebarHeader/sidebarHeader";
import { FoldedContentConsumer } from "../../../utility/context/toggleContentContext";

import { sidebarCollapsed } from "../../../redux/actions";

class Sidebar extends Component {
  state = {
    collapsedSidebar: this.props.collapsed,
    width: window.innerWidth,
  };
  updateWidth = () => {
    this.setState(() => ({
      width: window.innerWidth,
    }));
  };

  handleCollapsedSidebar = (collapsedSidebar) => {
    this.setState({ collapsedSidebar });
  };

  componentDidMount() {
    if (window !== "undefined") {
      window.addEventListener("resize", this.updateWidth, false);
    }
  }

  componentWillUnmount() {
    if (window !== "undefined") {
      window.removeEventListener("resize", this.updateWidth, false);
    }
  }
  handleMouseEnter = () => {
    this.setState(() => ({
      collapsedSidebar: false,
    }));
  };

  handleMouseLeave = () => {
    this.setState(() => ({
      collapsedSidebar: true,
    }));
  };

  render() {
    return (
      <Fragment>
        <FoldedContentConsumer>
          {(context) => (
            <div
              style={{
                boxShadow: "2px 60px 30px 0px rgba(0, 0, 0, 0.42)",
              }}
              data-active-color="white"
              className={classnames(
                "gradient-deep-orange-orange",
                "app-sidebar",
                {
                  "": !this.state.collapsedSidebar,
                  collapsed: this.state.collapsedSidebar,
                },
                {
                  "hide-sidebar": this.state.width < 991 && this.props.sidebarState === "close",
                  "": this.props.sidebarState === "open",
                }
              )}
              onMouseEnter={context.foldedContent ? this.handleMouseEnter : null}
              onMouseLeave={context.foldedContent ? this.handleMouseLeave : null}
            >
              <SidebarHeader toggleSidebarMenu={this.props.toggleSidebarMenu} sidebarBgColor={this.props.color} />
              <PerfectScrollbar className="sidebar-content">
                <SideMenuContent collapsedSidebar={this.state.collapsedSidebar} toggleSidebarMenu={this.props.toggleSidebarMenu} />
              </PerfectScrollbar>
            </div>
          )}
        </FoldedContentConsumer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  collapsed: state.menu.collapsed,
});

const mapDispatchToProps = (dispatch) => ({
  sidebarCollapsed: (collapsed) => dispatch(sidebarCollapsed(collapsed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
