import React from "react";

const Footer = (props) => (
  <footer>
    <div className="container-fluid" style={{ paddingBottom: 10 }}>
      <p className="text-center">
        Smilefish © {new Date().getFullYear()}{" "}
        <a href={`https://${(props.user.whitelabel || {}).domain}`} rel="noopener noreferrer" target="_blank">
          {(props.user.whitelabel || {}).name || ""}
        </a>
      </p>
    </div>
  </footer>
);

export default Footer;
