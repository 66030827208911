// import external modules
import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Spinner from "../components/spinner/spinner";

// import internal(own) modules
import MainLayoutRoutes from "../layouts/routes/mainRoutes";
import FullPageLayout from "../layouts/routes/fullpageRoutes";
import ErrorLayoutRoute from "../layouts/routes/errorRoutes";

import RequireAuth from "../components/RequireAuth";
import ScrollToTop from "../components/scrollToTop";
import ErrorPage from "../views/error";

const appRoutes = [
  {
    Layout: FullPageLayout,
    path: "/login",
    Component: lazy(() => import("../views/login")),
  },
  {
    Layout: FullPageLayout,
    path: "/forgot-password",
    Component: lazy(() => import("../views/forgotPassword")),
  },
  {
    Layout: FullPageLayout,
    path: "/reset-password",
    Component: lazy(() => import("../views/resetPassword")),
  },
  {
    Layout: FullPageLayout,
    path: "/verify-email",
    Component: lazy(() => import("../views/verifyEmail")),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/",
    Component: RequireAuth(lazy(() => import("../views/dashboard"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/user-profile",
    Component: RequireAuth(lazy(() => import("../views/users/userProfile"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/users",
    Component: RequireAuth(lazy(() => import("../views/users/usersIndex"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/user-details",
    Component: RequireAuth(lazy(() => import("../views/users/userDetails"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/patient-overview",
    Component: RequireAuth(lazy(() => import("../views/patientsOverview/index"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/patient-details",
    Component: RequireAuth(lazy(() => import("../views/patientsOverview/details"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/locations",
    Component: RequireAuth(lazy(() => import("../views/fhirTables/locations"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/patients",
    Component: RequireAuth(lazy(() => import("../views/fhirTables/patients"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/encounters",
    Component: RequireAuth(lazy(() => import("../views/fhirTables/encounters"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/practitioners",
    Component: RequireAuth(lazy(() => import("../views/fhirTables/practitioners"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/observations",
    Component: RequireAuth(lazy(() => import("../views/fhirTables/observations"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/datasources",
    Component: RequireAuth(lazy(() => import("../views/datasources/index"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/datasource-details",
    Component: RequireAuth(lazy(() => import("../views/datasources/details"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/tableau-report-view",
    Component: RequireAuth(lazy(() => import("../views/reports/tableauReport"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/quick-search",
    Component: RequireAuth(lazy(() => import("../views/quickSearch/index"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/master-patient-index",
    Component: RequireAuth(lazy(() => import("../views/patientsOverview/index"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/data-policies",
    Component: RequireAuth(lazy(() => import("../views/dataPolicies/index"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/data-policy-details",
    Component: RequireAuth(lazy(() => import("../views/dataPolicies/details"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/reports",
    Component: RequireAuth(lazy(() => import("../views/reports/index"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/report-details",
    Component: RequireAuth(lazy(() => import("../views/reports/details"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/etl-jobs",
    Component: RequireAuth(lazy(() => import("../views/etlJobs/index"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/etl-job-details",
    Component: RequireAuth(lazy(() => import("../views/etlJobs/details"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/ai-models",
    Component: RequireAuth(lazy(() => import("../views/aiModels/index"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/ai-model-details",
    Component: RequireAuth(lazy(() => import("../views/aiModels/details"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/patients-analytics",
    Component: RequireAuth(lazy(() => import("../views/analytics/patients"))),
  },
  {
    Layout: MainLayoutRoutes,
    path: "/volume-analytics",
    Component: RequireAuth(lazy(() => import("../views/analytics/volume"))),
  },
];

class Router extends Component {
  render() {
    const routeArr = [];
    appRoutes.forEach((r) => {
      const { Layout, Component } = r;
      routeArr.push(
        <Layout
          key={r.path}
          exact
          path={r.path}
          render={(matchprops) => (
            <Suspense fallback={<Spinner />}>
              <Component {...matchprops} key={matchprops.location.key} />
            </Suspense>
          )}
        />
      );
    });
    return (
      // Set the directory path if you are deplying in sub-folder
      <BrowserRouter basename="/">
        <>
          <ScrollToTop />
          <Switch>
            {routeArr}
            <ErrorLayoutRoute
              exact
              path="/error"
              render={(matchprops) => (
                <Suspense fallback={<Spinner />}>
                  <ErrorPage {...matchprops} />
                </Suspense>
              )}
            />
            <ErrorLayoutRoute
              render={(matchprops) => (
                <Suspense fallback={<Spinner />}>
                  <ErrorPage {...matchprops} />
                </Suspense>
              )}
            />
          </Switch>
        </>
      </BrowserRouter>
    );
  }
}

export default Router;
